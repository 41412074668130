<template>
  <v-form ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Nome"
            name="nome"
            placeholder="Nome"
            v-model="nome"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Situação"
            name="situacao"
            placeholder="Situação"
            v-model="situacao"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Praça"
            name="praca"
            placeholder="Praça"
            v-model="praca"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field class="mr-2" label="Cep" name="cep" placeholder="Cep" v-model="cep" :readonly="true">
          </v-text-field>
          <v-text-field
            class="mr-2"
            label="Cidade"
            name="cidade"
            placeholder="Cidade"
            v-model="cidade"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field class="mr-2" label="Rua" name="rua" placeholder="Rua" v-model="rua" :readonly="true">
          </v-text-field>
        </div>
      </v-flex>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <v-text-field class="mr-2" label="CPF" name="cpf" placeholder="CPF" v-model="cpf" :readonly="true">
          </v-text-field>
          <v-text-field class="mr-2" label="RG" name="rg" placeholder="RG" v-model="rg" :readonly="true"></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Telefone"
            name="telefone"
            placeholder="Telefone"
            v-model="telefone1"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Telefone"
            name="telefone2"
            placeholder="Telefone"
            v-model="telefone2"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Data nascimento"
            name="dtNascimento"
            placeholder="Data nascimento"
            v-model="dtNascimento"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Bairro"
            name="bairro"
            placeholder="Bairro"
            v-model="bairro"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Número"
            name="numero"
            placeholder="Número"
            v-model="numero"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Estado"
            name="estado"
            placeholder="Estado"
            v-model="estado"
            :readonly="true"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <select-praca class="mr-2"></select-praca>
        <select-vendedor class="mr-2"></select-vendedor>
      </v-flex>
    </v-layout>
    <div class="form-btn">
      <v-btn @click="voltar" outline color="primary">Voltar</v-btn>
      <v-btn outline color="primary" @click="salvar()">Salvar</v-btn>
    </div>
    <div>
      <progress-circular></progress-circular>
    </div>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import SelectPraca from "../praca/selectPracaPorFuncionario.vue"
import SelectVendedor from "../vendedor/selectVendedor"
import ProgressCircular from "../progressCircular/progressCircular"
import { ServicoVendedor } from "../../servicos/servicoVendedor"
const servicoVendedor = new ServicoVendedor()
export default {
  components: {
    SelectPraca,
    SelectVendedor,
    ProgressCircular
  },
  data() {
    return {
      id: null,
      situacao: "",
      praca: "",
      cpf: "",
      nome: "",
      rg: "",
      telefone1: "",
      telefone2: "",
      dtNascimento: "",
      cep: "",
      cidade: "",
      bairro: "",
      rua: "",
      numero: "",
      estado: "",
      codVendedor: null
    }
  },
  created() {
    serverBus.$on("pracaSelected", value => {
      serverBus.$emit("notasaida", value)
    })
    serverBus.$on("vendedorSelected", value => {
      this.codVendedor = value.id
    })
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoVendedor.buscarPorId(this.id).then(res => {
        this.id = res.data.id
        this.situacao = res.data.situacao
        this.praca = res.data.praca
        this.cpf = res.data.cpf
        this.nome = res.data.nome
        this.rg = res.data.rg
        this.telefone1 = res.data.telefone1
        this.telefone2 = res.data.telefone2
        this.dtNascimento = res.data.dtNascimento
        this.cep = res.data.cep
        this.cidade = res.data.cidade
        this.bairro = res.data.bairro
        this.rua = res.data.rua
        this.numero = res.data.numero
        this.estado = res.data.estado
      })
    }
  },
  methods: {
    voltar() {
      this.$router.push({ name: "Vendedores" })
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    },
    salvar() {
      this.abrirProgressCircular()
      if (this.$refs.form.validate()) {
        servicoVendedor.unificar(this.id, this.codVendedor).then(res => {
          if (res.status === 200) {
            this.fecharProgressCircular()
            this.voltar()
          }
        })
      } else {
        this.fecharProgressCircular()
      }
    }
  }
}
</script>
